import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

const useStyles = makeStyles(theme => ({
  img: {
    maxHeight: 600,
  },
}))

function ImageCarousel({ fluidImg, description }) {
  const classes = useStyles()

  return (
    <div className="p-3">
      <Card className={classes.card}>
        <CardContent>
          <Img
            fluid={fluidImg}
            fadeIn={true}
            objectFit="contain"
            className={classes.img}
          />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className="pt-3 pb-3 px-3"
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </div>
  )
}

ImageCarousel.propTypes = {
  fluidImg: PropTypes.any,
  description: PropTypes.string.isRequired,
}

export default ImageCarousel
