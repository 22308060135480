import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { Grid } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import theme from "../../theme"

const cardProps = {
  borderRadius: "20px 20px 20px 20px",
}

const textStyle = {
  fontFamily: "'Crimson Pro', serif",
  fontSize: "1.5rem",
}

const ExpansionPanel = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion)

export default function IntroSummary() {
  const [expanded, setExpanded] = React.useState("introPanel")

  const handleChange = (panel) => (_event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <Container className="text-center py-5 px-3 px-md-5" id="introSummary">
      <Row className="justify-content-md-center">
        <Col md="12" lg="9">
          <div className="align-top">
            <Card style={cardProps} raised={false}>
              <CardContent>
                <p className="px-lg-4" style={textStyle}>
                  Based out of quaint Safety Harbor, Florida, I have been
                  working at an automotive software solutions provider for the
                  past <span id="loopWorkYears">7</span> years. While working
                  during the day, ensuring our products meet the highest quality
                  standards and over countless nights &amp; weekends, I have
                  refined several skills and picked up more here and there.
                </p>

                <p className="px-lg-4" style={textStyle}>
                  I know what it takes to design, build and deliver a quality
                  product - I love breaking things, taking it apart and I enjoy
                  building them.
                </p>
                <p className="px-lg-4" style={textStyle}>
                  I have never let a role define me and I am always up for a
                  challenge. I strongly believe in keeping an open mind,
                  learning from experiences from people of all walks of life,
                  every day.
                </p>
                <p className="px-lg-4" style={textStyle}>
                  Feel free to poke around and if you're intrigued, let's
                  connect!
                </p>
              </CardContent>
              <CardActions>
                <div>
                  <ExpansionPanel
                    expanded={expanded === "storyPanel"}
                    onChange={handleChange("storyPanel")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      id="storyPanel-header"
                      style={{ color: theme.palette.secondary.main }}
                      className="bold90Spaced text-uppercase"
                    >
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        What's my story?
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails id="storyPanel-details">
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                      >
                        <p className="px-lg-4" style={textStyle}>
                          Tinkering with things has always been my passion. Ever
                          since I was a kid, I would open things up and try to
                          figure out how it all worked. My family and I had
                          immigrated to the United States in 2010 and I had
                          officially started my career at Best Buy within a
                          month of moving in, right when Amazon was gunning
                          straight for us.
                        </p>
                        <p className="px-lg-4" style={textStyle}>
                          It was instilled in me that the customer experience
                          was the biggest differentiator that would help us win
                          our customers back. We strived to deliver on that and
                          I saw hands-on how we were able to keep customers in
                          the store, continue buying from us and ultimately
                          reach the top in sales & customer satisfaction. I was
                          promoted to Lead Mobile Sales Consultant and helped
                          our team lead as an interim manager to close amongst
                          the top 100 stores nationwide over several quarters.
                        </p>
                        <p className="px-lg-4" style={textStyle}>
                          In 2014, I was at a crossroad - either continue on my
                          path in Sales and/or Management or pursue my passion
                          for Technology. I came across Affinitiv (formerly
                          AutoLoop), an automotive software solutions provider
                          based out of Clearwater, Florida. I had an eagerness
                          to learn and a lot to prove. I turned our numbers
                          around and brought down our data integration downtime
                          from 20% to less than 1% within the first 6 months. I
                          single-handedly completed over 85% of our on-boarding
                          for our nationwide Mercedes-Benz program which earned
                          me a spot at the official MBUSA Brand Immersion
                          Experience in Alabama.
                        </p>
                        <p className="px-lg-4" style={textStyle}>
                          Shortly thereafter, I moved up to our Engineering
                          teams as a fellow QA. My thirst for challenges and my
                          investigative tingliness brought forth lots of
                          opportunities to learn &amp; dig deeper into our
                          entire product suite. I learned and delivered &amp; I
                          moved up the ranks to my current position as a Senior
                          Automation Engineer where I lead the automation
                          efforts for all our products. I earned two Top
                          Performer Awards - the only employee to have received
                          such an honor. I love being involved in the product
                          design phase and I love to see it getting delivered.
                          Over my time here, I have contributed immensely to
                          expand and build out our testing frameworks, increased
                          test coverage and helped solidify our product
                          offerings. I like to break things and I have fun doing
                          it.
                        </p>
                      </Grid>
                    </AccordionDetails>
                  </ExpansionPanel>
                </div>
              </CardActions>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
