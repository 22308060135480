import React from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

const iconColor = "#5627d8"

function SkillListItem({ title, description }) {
  if (typeof title === "undefined") {
    return (
      <li className="list-group-item list-group-item-action borderless">
        <FontAwesomeIcon icon={faChevronRight} color={iconColor} />
        <span> {description}</span>
      </li>
    )
  } else {
    return (
      <li className="list-group-item list-group-item-action borderless">
        <FontAwesomeIcon icon={faChevronRight} color={iconColor} />
        <strong> {title}</strong> - {description}
      </li>
    )
  }
}

SkillListItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
}

export default SkillListItem
