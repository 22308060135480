import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IntroSection from "../components/sections/introSection"
import IntroSummary from "../components/sections/introSummary"
import MySkills from "../components/sections/mySkills"
import WorkHistory from "../components/sections/workHistory"
import Showcase from "../components/sections/showcase"

const introMainSection = {
  width: "100%",
  height: "50%",
  minHeight: "50vh",
  maxHeight: "50vh",
  position: "relative",
  display: "flex",
  WebkitBoxAlign: "center",
  msFlexAlign: "center",
  alignItems: "center",
  WebkitBoxPack: "center",
  msFlexPack: "center",
  justifyContent: "center",
  WebkitBoxOrient: "vertical",
  WebkitBoxDirection: "normal",
  msFlexDirection: "column",
  flexDirection: "column",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundClip: "initial",
  backgroundRepeatt: "no-repeat",
  backgroundAttachment: "fixed",
}

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO keywords={[`TNoor`, `TJ Noor`, `Developer`]} />
    <section id="hello" style={introMainSection}>
      <IntroSection />
    </section>
    <IntroSummary />
    <section id="skills">
      <MySkills />
    </section>
    <section id="workHistory">
      <WorkHistory />
    </section>
    <section id="showcase">
      <Showcase />
    </section>
  </Layout>
)

export default IndexPage
