import React from "react"
import PropTypes from "prop-types"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import { Typography, Grid } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Button from "@material-ui/core/Button"
import SkillListItem from "./skillListItem"
import theme from "../../theme"

const cardProps = {
  borderRadius: "20px 20px 20px 20px",
  paddingLeft: "0",
  paddingRight: "0",
}

function HistoryCard({ title, company, shortDescription, longDescription }) {
  const [open, setOpen] = React.useState(false)

  const openDialog = () => () => {
    setOpen(true)
  }

  function closeDialog() {
    setOpen(false)
  }

  return (
    <div>
      <Card style={cardProps} raised={true}>
        <CardContent>
          <h5 className="text-uppercase boldSpaced">{title}</h5>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            className="text-uppercase bold90Spaced"
          >
            {company}
          </Typography>
          <p className="py-3">{shortDescription}</p>
        </CardContent>
        <CardActions>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Button
              size="small"
              onClick={openDialog()}
              style={{ color: theme.palette.secondary.main }}
              className="bold90Spaced"
            >
              Learn More
            </Button>
          </Grid>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        onClose={closeDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        style={cardProps}
      >
        <DialogTitle id="scroll-dialog-title">
          {title} @ {company}
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText>
            {longDescription.map((desc, index) => (
              <SkillListItem key={index} description={desc} />
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeDialog}
            color="primary"
            className="bold90Spaced"
            style={{ color: theme.palette.secondary.main }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

HistoryCard.propTypes = {
  title: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  longDescription: PropTypes.array.isRequired,
}

export default HistoryCard
