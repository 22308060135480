import React from "react"
import { Container, Card, CardDeck } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileCode } from "@fortawesome/free-regular-svg-icons"
import { faBug, faPaintBrush } from "@fortawesome/free-solid-svg-icons"
import SkillListItem from "../widgets/skillListItem"
import { Typography } from "@material-ui/core"

const cardProps = {
  borderRadius: "20px 20px 20px 20px",
}

const titleProps = {
  letterSpacing: ".3rem",
}

const subtitleProps = {
  color: "#5627d8",
}

const iconColor = "#5627d8"

const MySkills = () => (
  <Container className="text-center py-3 justify-content-center align-items-start m-auto px-3 px-md-5">
    <Typography variant="h5" className="pb-5 text-uppercase sectionTitle">
      I have a very particular set of skills ...
    </Typography>
    <CardDeck>
      <Card className="shadow-sm" style={cardProps}>
        <Card.Body>
          <div className="align-top">
            <div>
              <FontAwesomeIcon
                icon={faFileCode}
                title="Coding File"
                color={iconColor}
                size="2x"
              />
            </div>
            <h5
              className="p-3 text-uppercase font-weight-bold"
              style={titleProps}
            >
              Developer
            </h5>
          </div>
          <p className="p-2 font-weight-light">
            I love bringing ideas to life with clean &amp; abstracted code.
          </p>
          <p
            className="p-1 text-uppercase boldSpacedPadded"
            style={subtitleProps}
          >
            Things I have built &amp; worked on so far:
          </p>
          <ul className="list-group list-group-flush text-left font-weight-light">
            <SkillListItem
              key="d1"
              title="iOS"
              description="Xamarin Essentials (C#) &amp; Flutter (Dart)"
            />
            <SkillListItem
              key="d2"
              title="Android"
              description="Native (Java), Xamarin Essentials (C#) &
                  Flutter (Dart)"
            />
            <SkillListItem
              key="d5"
              title="Progressive Web Apps"
              description="Psst, this is one"
            />
            <SkillListItem
              key="d6"
              title="Browser Extensions"
              description="Chrome"
            />
            <SkillListItem
              key="d7"
              title="Web Apps &amp; Static Sites"
              description="React, Gatsby,
                  Express, Vue.js &amp; Pug"
            />
            <SkillListItem
              key="d8"
              title="Serverless Functions"
              description="Node.js (AWS Lambda, Firebase
                  and Twilio) &amp; C# (Azure Functions)"
            />
            <SkillListItem key="d9" title="Console Apps" description="C#" />
            <SkillListItem
              key="d11"
              title="Database"
              description="MySQL, MSSQL, SQLite, Azure CosmosDb &amp; Firebase Database"
            />
          </ul>
        </Card.Body>
      </Card>
      <div className="w-100 d-block d-md-block d-lg-none mt-4"></div>
      <Card className="shadow-sm" style={cardProps}>
        <Card.Body>
          <div className="align-top">
            <div>
              <FontAwesomeIcon
                icon={faBug}
                title="Software Bug"
                color={iconColor}
                size="2x"
              />
            </div>
            <h5
              className="p-3 text-uppercase font-weight-bold"
              style={titleProps}
            >
              Tester
            </h5>
          </div>
          <p className="p-2 font-weight-light">
            I enjoy putting my detective hat on &amp; I live to automate and
            break things.
          </p>
          <p
            className="p-1 text-uppercase boldSpacedPadded"
            style={subtitleProps}
          >
            Things I have built &amp; tested with so far:
          </p>
          <ul className="list-group list-group-flush text-left font-weight-light">
            <SkillListItem
              key="t0"
              title="Test Automation"
              description=".NET based API, UI (Selenium) &amp; Mobile (Appium) Test Automation"
            />
            <SkillListItem
              key="t6"
              title="Automation Framework Integrations"
              description="Chrome Extensions, Email, Text, CDN, Microsoft Teams, Jira, DataDog, TeamCity &amp; TestRail Integration"
            />
            <SkillListItem
              key="t1"
              title="Performance Testing"
              description="JMeter Load and Stress Tests with Custom C# Report Generator"
            />
            <SkillListItem
              key="t2"
              title="Application Security"
              description="Vulnerability Assessment, Code Analysis &amp; Penetration Testing"
            />
            <SkillListItem
              key="t3"
              title="Web Automation"
              description="Selenium Framework with Docker &amp; Grid Support"
            />
            <SkillListItem
              key="t4"
              title="Mobile Automation"
              description="Appium (iOS and Android) &amp; Flutter (Dart)"
            />
            <SkillListItem
              key="t5"
              title="API Automation"
              description="C# &amp; Postman Collection Runner"
            />
            <SkillListItem
              key="t7"
              title="Manual Testing"
              description="Black Box and White Box Testing with Database Verification &amp; Code Analysis"
            />
          </ul>
        </Card.Body>
      </Card>
      <div className="w-100 d-block d-md-block d-lg-none mt-4"></div>
      <Card className="shadow-sm py-sm-3 py-lg-0" style={cardProps}>
        <Card.Body>
          <div className="align-top">
            <div>
              <FontAwesomeIcon
                icon={faPaintBrush}
                title="Paint Brush"
                color={iconColor}
                size="2x"
              />
            </div>
            <h5
              className="p-3 text-uppercase font-weight-bold"
              style={titleProps}
            >
              Consultant
            </h5>
          </div>
          <p className="p-2 font-weight-light">
            Product usability and intuitive user experiences drive me to help
            better serve a project.
          </p>
          <p
            className="p-1 text-uppercase boldSpacedPadded"
            style={subtitleProps}
          >
            Things I like to contribute towards:
          </p>
          <ul className="list-group list-group-flush text-left font-weight-light">
            <SkillListItem
              key="c1"
              title="Requirements"
              description="Creating and Refining Techinical and Business Requirements &amp; making it the best version of itself"
            />
            <SkillListItem
              key="c2"
              title="UX Design"
              description="I put myself in the shoes of the user and I live for clean interfaces that are feature-packed"
            />
            <SkillListItem
              key="c3"
              title="Product Speciality"
              description="Automotive Solutions, Voice and Texting Platforms &amp; Mobile Applications"
            />
            <SkillListItem
              key="c4"
              title="Brainstorming"
              description="I live for this. Let's chat and maybe we can make something magical"
            />
          </ul>
        </Card.Body>
      </Card>
    </CardDeck>
  </Container>
)

export default MySkills
