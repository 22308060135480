import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const introText = {
  minHeight: "100vh",
}

const subtitleText = {
  color: "#2c2c2c",
  textShadow: "1px 1px 1px #fff",
}

function IsMinimumWidth() {
  return useMediaQuery("(min-width:360px)")
}

const IntroSection = () => (
  <StaticQuery
    query={graphql`
      query {
        background: file(
          relativePath: { eq: "background/stormTrooperKeyboard.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        avatar: file(relativePath: { eq: "bitmoji/hi.png" }) {
          childImageSharp {
            fixed(width: 125) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => {
      const backgroundImg = data.background.childImageSharp.fluid
      const avatarImg = data.avatar.childImageSharp.fixed

      return (
        <Container className="container-fluid no-padding">
          <BackgroundImage fluid={backgroundImg} backgroundColor={`#040e18`}>
            <Row>
              <Col>
                <div
                  className="container-fluid text-center d-flex justify-content-center align-items-center"
                  style={introText}
                >
                  <div>
                    <h3
                      className="p-3 text-uppercase boldSpacedPadded"
                      style={subtitleText}
                    >
                      Hello, World.
                    </h3>
                    <Img fixed={avatarImg} fadeIn={true} alt="tj" />
                    <h1 className="p-3 font-weight-bolder">
                      <kbd>I am TJ Noor</kbd>
                    </h1>
                    <div className={IsMinimumWidth() ? "typewriter" : null}>
                      <h3
                        className="p-0 text-uppercase boldSpacedPadded"
                        style={subtitleText}
                      >
                        Developer, Tinkerer &amp; Tech Enthusiast
                      </h3>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </BackgroundImage>
        </Container>
      )
    }}
  />
)

export default IntroSection
