import React from "react"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import { graphql, StaticQuery } from "gatsby"
import { Container } from "react-bootstrap"
import { Typography } from "@material-ui/core"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLaptopCode,
  faBug,
  faDollarSign,
  faUserAstronaut,
  faRobot,
  faServer,
} from "@fortawesome/free-solid-svg-icons"
import "../../styles/_workHistory.scss"
import theme from "../../theme"
import HistoryCard from "../widgets/historyCard"

const workIcon = (title, faIcon) => (
  <FontAwesomeIcon
    icon={faIcon}
    title={title}
    color={theme.palette.primary.main}
    size="lg"
  />
)

const WorkHistory = () => (
  <StaticQuery
    query={graphql`
      query {
        avatar: file(relativePath: { eq: "bitmoji/hamster.png" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => {
      const avatarImg = data.avatar.childImageSharp.fixed
      return (
        <Container className="text-center p-auto py-3 px-3 px-md-5">
          <Typography variant="h5" className="text-uppercase sectionTitle">
            What have I been up to?
          </Typography>
          <VerticalTimeline>
            <VerticalTimelineElement
              date="Feb 2019 - present"
              iconStyle={{ background: "#fff", color: "#fff" }}
              icon={workIcon("coding on laptop", faLaptopCode)}
            >
              <HistoryCard
                title="Senior Automation Engineer"
                company="Affinitiv"
                shortDescription="Focused on Building Automation Tools, Performance Testing, Application Security and Incubating Frameworks, while documenting & training fellow Automation Engineers."
                longDescription={[
                  "Revamped Automation Framework (.NET) by combining over 4 teams and several projects under a single solution, created a shared framework thus reducing duplicated code, effort and fostering more team growth.",
                  "Built Automated Test Runners (C# Console App) to execute daily test runs, analyze results from TestRail and proceed with needed reruns throughout the day, saving several hours of daily manual work.",
                  "Moved UI Test Execution for Selenium to AWS EC2 instances to support our rapidly growing tests (4000+). Consistently working to ensure reliable results along with the highest level of parallelization.",
                  "Incorporated processes embracing Pull Requests on GitHub to ensure all code changes are reviewed and training & feedback is provided right away. This has vastly improved code quality and the teams skill in writing robust tests.",
                  "Provide day-to-day support to fellow Automation Engineers in coding and challenging situations",
                ]}
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              date="Jun 2017 - present"
              iconStyle={{ background: "#fff", color: "#fff" }}
              icon={workIcon("Astronaut", faUserAstronaut)}
            >
              <HistoryCard
                title="Co-Founder & Developer"
                company="Noor By Design"
                shortDescription="Always learning and bringing ideas to life - mobile, voice, desktop & server."
                longDescription={[
                  "iOS & Android - Built ScanThatCar, a License Plate (using Google MLKit) & VIN Decoding tool, using Flutter (Dart). Total Downloads in the first 10 months: 10,000+ and averaging over 50 new downloads a day.",
                  "iOS & Android - Built linebreakr, a tool to format text for Social Media using Unicode Characters & hashtag storage, using Flutter (Dart). Total Downloads: 500+",
                  "iOS - Built whodisPro, a free reverse phone lookup service, using Xamarin Essentials (C#).",
                  "Android - Built whodis, using native Android Development on Java.",
                  "Conversational Bots - Built whodis on Alexa, Voice Phone & Text",
                  "WebApp - Built whodisPro on Azure with Express, Pug & Vue.js",
                  "APIs - Built RESTful endpoints to help power whodisPro on Azure Functions",
                  "whodis had an average cumulative number of Sessions Per Month of 8000+ & had Unique Users on iOS, Android, Alexa & Web totaling up to 2368",
                  "Progressive Web Apps - Built this site with support for PWA using Gatsby (React).",
                  "Chrome Browser Extension - Built AutoLoop JIRA Helper, a tool to automate test subtask creation and to help with Engineering & QA time management.",
                  "Chrome Browser Extension - Built TradeInValet Helper, a tool to help debug Trade-In Valet.",
                  "Chrome Browser Extension - Built Ebay Template Generator, a tool to generate responsive Ebay listings with CDN support.",
                  "Console App - Built JMeter Report Generator, a tool to parse and generate graphs from raw JMeter test runs, using C#.",
                  "Database - Built a number of databases for the various applications mentioned above on MySQL, MSSQL, SQLite, Azure CosmosDb & Firebase Database.",
                ]}
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              date="Oct 2016 to Feb 2019"
              iconStyle={{ background: "#fff", color: "#fff" }}
              icon={workIcon("robot", faRobot)}
            >
              <HistoryCard
                title="Automation Engineer"
                company="Affinitiv (formerly AutoLoop)"
                shortDescription="White Box Testing our Equity Mining Suite, Service Lane Technology & Mobile Applications while building out frameworks for Automation."
                longDescription={[
                  "Increased automated test coverage for our Sales Suite from 5% to over 90% in just a few months.",
                  "Converted all smoke tests to automated tests that runs after code deployment, eliminating the need for multiple manual testers and saving over 85% of the time taken.",
                  "Expanded Automation Framework to cover end-to-end text, email and API testing, eliminating the need for hours of manual work across platforms.",
                  "Built a Mobile Automation Framework on Appium to support our native iOS, iPadOS & Android apps. ",
                  "Designed the UI+UX behind Trade-In Valet Express and the Trade-In Valet overhaul after AutoLoop acquisition.",
                  "In charge of managing the listing, deploying and releasing our Chrome Browser Extension.",
                  "Expertise in working on the entire AutoLoop suite, especially our Mobile Apps (6+) in the Android & iOS Platform, CRM, the Service Scheduling Suite, and the Equity Mining Suite.",
                  "Awarded AutoLoop's Top Performer Award in 2018 and won ALECon Hackathon in 2017.",
                ]}
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              date="Aug 2015 - Oct 2016"
              iconStyle={{ background: "#fff", color: "#fff" }}
              icon={workIcon("software bug", faBug)}
            >
              <HistoryCard
                title="Senior QA Analyst"
                company="Affinitiv (formerly AutoLoop)"
                shortDescription="Lead 4 out of the 8 Engineering Teams for Black Box Testing & Special Projects"
                longDescription={[
                  "In charge of 4 of our 8 Engineering Teams for Black Box QA Testing & Special Projects. Products in those teams include iOS apps, Win10 apps, apps based on .NET Framework, Integration with DMS's & other third parties and Web-based software such as AutoBook (Appointment Scheduling), Portal (Online Customer Management), AutoQuote (Equity Mining), Showroom (CRM) and so on.",
                  "Test API’s with OEM Partners like Subaru, Toyota & Mercedes-Benz and various other Third Party Vendors.",
                  "In charge of training and mentoring other QA & Jr. QA Analysts to better their product knowledge and their skills in testing the software.",
                  "Handle priority bug cases escalated by Tier 3 Support to properly document & verify the validity of the bug.",
                  "Directly work with internal and external stakeholders to identify, test and implement solutions to bugs, features, and enhancements.",
                  "Awarded AutoLoop's Top Performer Award in 2016.",
                ]}
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              date="Mar 2014 - Aug 2015"
              iconStyle={{ background: "#fff", color: "#fff" }}
              icon={workIcon("Server Stack", faServer)}
            >
              <HistoryCard
                title="System & Data Integration Specialist"
                company="Affinitiv (formerly AutoLoop)"
                shortDescription="Onboard, Monitor and Support Data Integration between Automotive Dealership Management Systems and AutoLoop Servers."
                longDescription={[
                  "Install & set up the near real-time data integration between Dealership Management Systems (DMS) and our servers so that our software & algorithm can work off of it. There are over 20 different DMS’s.",
                  "Handle legal agreements from Dealerships & our Legal Team. Ensure they are signed and any additional required information is provided when requested.",
                  "Maintain the upkeep of our software that integrates with the DMS’s. There was over 2000 dealerships & DMS’s. Handle Support Cases escalated by our Tier 2 Support team.",
                  "Awarded with a trip to Mercedes-Benz Brand Immersion Experience for contribution to the TouchPoint rollout",
                ]}
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              date="Oct 2010 - Mar 2014"
              iconStyle={{ background: "#fff", color: "#fff" }}
              icon={workIcon("Money", faDollarSign)}
            >
              <HistoryCard
                title="Mobile Lead Sales Consultant"
                company="Best Buy"
                shortDescription="Listened and helped Customers find the perfect phone they wanted, with the best plan for their needs along with an array of Geek Squad technical services that followed."
                longDescription={[
                  "Supported the Best Buy Mobile Manager with the day to day operations of the Mobile Phone, Tablets, MP3, Health & Fitness and Networking department of the largest electronic retail store in West Coast Florida.",
                  "Filled in the position of interim manager for a few months and closed amongst the top 100 stores out of 1200 in the country from the mid-700s",
                  "Helped train and coach fellow associates in being proficient with new products to demo & wow customers along with ways to achieve business goals.",
                  "Helped customers find the perfect solution for their wireless, networking & health monitoring needs and made sure they experienced a world class interaction at our store. Providing such an experience to the customer was key in differentiating us from all other retailers out there.",
                  "Provided Geek Squad Services which included phone replacements, troubleshooting mobile phones, tablets & mobile hotspots and assist with data retrieval.",
                ]}
              />
            </VerticalTimelineElement>
            <VerticalTimelineElement
              iconStyle={{ background: "#fff", color: "#fff" }}
              icon={
                <Img
                  fixed={avatarImg}
                  fadeIn={true}
                  alt="TJ in a hamster wheel"
                  objectFit="cover"
                />
              }
            />
          </VerticalTimeline>
        </Container>
      )
    }}
  />
)

export default WorkHistory
