import React from "react"
import Carousel from "nuka-carousel"
import { graphql, StaticQuery } from "gatsby"
import { Container } from "react-bootstrap"
import { Typography } from "@material-ui/core"
import ImageCarousel from "../widgets/imageCarousel"

const Showcase = () => (
  <StaticQuery
    query={graphql`
      query {
        alecon: file(relativePath: { eq: "snapshots/alecon.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        atp2016: file(relativePath: { eq: "snapshots/atp2016.png" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        atp2018: file(relativePath: { eq: "snapshots/atp2018.JPG" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        awards: file(relativePath: { eq: "snapshots/awards.JPG" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mbenz: file(relativePath: { eq: "snapshots/mbenz.JPG" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      const aleconImg = data.alecon.childImageSharp.fluid
      const atp2016Img = data.atp2016.childImageSharp.fluid
      const atp2018Img = data.atp2018.childImageSharp.fluid
      const awardsImg = data.awards.childImageSharp.fluid
      const mbenzImg = data.mbenz.childImageSharp.fluid
      return (
        <Container className="text-center py-3 px-3">
          <Typography variant="h5" className="text-uppercase sectionTitle">
            Some Very Exciting Moments ...
          </Typography>
          <Carousel
            autoplay={true}
            // heightMode="first"
            // width={"80%"}
            wrapAround={true}
            transitionMode="scroll3d"
            framePadding="10px"
            cellAlign="center"
            withoutControls={true}
          >
            <ImageCarousel
              fluidImg={mbenzImg}
              description="April 2015 - At the Mercedes-Benz Brand Immersion Experience in their Alabama Factory"
            />
            <ImageCarousel
              fluidImg={atp2016Img}
              description="December 2016 - Receiving my first AutoLoop Top Performer Award from Steve Anderson, our President &amp; CEO, at our annual holiday party - 80's themed"
            />
            <ImageCarousel
              fluidImg={aleconImg}
              description="June 2017 - Won at the ALECon Hackathon for an all-encompassing mobile retail platform solution. Pictured here are our CTO, Chief Architect and my fellow Engineers."
            />
            <ImageCarousel
              fluidImg={atp2018Img}
              description="December 2018 - Receiving my second AutoLoop Top Performer Award from Steve Anderson, our President &amp; CEO, at our annual holiday party - masquerade ball themed"
            />
            <ImageCarousel
              fluidImg={awardsImg}
              description="Awards and Recognitions earned during my time at Affinitiv (formerly AutoLoop)"
            />
          </Carousel>
        </Container>
      )
    }}
  />
)

export default Showcase
